import React, {
  createContext,
  useState,
  useEffect,
  FunctionComponent
} from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import { config } from "./config";

export type AuthContextType = {
  user?: {
    userProfile: {
      app_metadata: {
        authorizations: {
          userManager?: {
            role: string;
          };
        };
      };
    };
  } | null;
  logout: () => void;
  token?: string;
};

export const AuthContext = createContext<AuthContextType>({
  logout: () => {}
});

export const AuthProvider: FunctionComponent = ({ children }) => {
  const [user, setUser] = useState<any | null>(null);
  const [token, setToken] = useState();
  const [auth0, setAuth0] = useState<any | null>(null);

  useEffect(() => {
    const init = async () => {
      const auth0 = await createAuth0Client({
        domain: config.domain,
        client_id: config.clientId,
        redirect_uri: config.redirect_uri,
        audience: config.audience
      });

      const user = await auth0.getUser();
      setUser(user);
      let token;
      try {
        token = await auth0.getTokenSilently();
      } catch (error) {
        console.error(error);
      }
      setToken(token);

      if (window.location.search.includes("code=")) {
        await auth0.handleRedirectCallback();
        onRedirectCallback();
        const user = await auth0.getUser();
        const token = await auth0.getTokenSilently();
        setUser(user);
        setToken(token);
      }

      setAuth0(auth0);
    };
    init();
  }, []);

  const onRedirectCallback = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

  const logout = async () => {
    auth0.logout();
    setUser(null);
    login();
  };

  const login = async () => {
    const user = await auth0.loginWithRedirect();
    setUser(user);
  };

  if (!auth0) return <div>Starting...</div>;
  if (!user) login();

  if (
    !user.userProfile.app_metadata.authorization.userManager ||
    user.userProfile.app_metadata.authorization.userManager.role !== "admin"
  )
    return (
      <div>
        Not authorized, please contact service desk to have permission upgraded
      </div>
    );

  return (
    <AuthContext.Provider value={{ user, logout, token }}>
      {children}
    </AuthContext.Provider>
  );
};
