import { fonts } from "@times-tooling/style-guide";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
svg {
  box-sizing: content-box;
}
html {
  font-size: 10px;
}
body {
  font-size: 1.4rem;
  line-height: 1.25;
  font-family: ${fonts.sansSerif};
}
html,
body {
  min-height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: lighter;
  margin: 0;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
h3 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
a {
  text-decoration: none;
}
`;
