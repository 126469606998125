import { editionRoleOrder, channelRoleOrder } from "@newsuk/times-edition-builder-permissions";

export const tools = [
  {
    id: "userManager",
    title: "User Manager",
    roles: ["none", "admin"]
  },
  {
    id: "editionBuilder",
    title: "Edition Builder",
    categories: [
      { title: "The Times", id: "times", roles: editionRoleOrder },
      { title: "Sunday Times", id: "sundaytimes", roles: editionRoleOrder },
      { title: "Channels", id: "channels", roles: channelRoleOrder }
    ]
  },
  {
    id: "authorPageEditor",
    title: "Author Page Editor",
    roles: ["none", "editor"]
  },
  {
    id: "algoliaDashboard",
    title: "Algolia Dashboard",
    roles: ["none", "editor"]
  },
  {
    id: "tagManager",
    title: "Tag Manager",
    roles: ["none", "editor"]
  }
];
