export let config;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case "prod":
    config = {
      domain: "newsuktooling-prod.eu.auth0.com",
      clientId: "pj8b9f0gxOdYjBAZoIaymZuuf87nsgUx",
      redirect_uri: "https://user-manager.tools.news",
      audience: "http://newsuk/usermanager/prod",
      apiUrl: "https://cu3672j677.execute-api.eu-west-1.amazonaws.com/prod",
    };
    break;
  case "staging":
    config = {
      domain: "newsuktooling-staging.eu.auth0.com",
      clientId: "Uvhn6MIDqDu4qAufpai7BQcarJf74xdC",
      redirect_uri: "https://user-manager-staging.tools.news",
      audience: "http://newsuk/usermanager/staging",
      apiUrl: "https://2842340y93.execute-api.eu-west-1.amazonaws.com/staging",
    };
    break;
  case "dev":
    config = {
      domain: "newsuktooling-dev.eu.auth0.com",
      clientId: "gwJJSO60a23LcvjQZbL2nktWKpt1qF1Y",
      redirect_uri: "https://user-manager-dev.tools.news",
      audience: "http://newsuk/usermanager/dev",
      apiUrl: "https://0t1u2ltqci.execute-api.eu-west-1.amazonaws.com/dev",
    };
    break;
  default:
    config = {
      domain: "newsuktooling-dev.eu.auth0.com",
      clientId: "gwJJSO60a23LcvjQZbL2nktWKpt1qF1Y",
      redirect_uri: "http://localhost:3000",
      audience: "http://newsuk/usermanager/dev",
      apiUrl: "http://localhost:3001",
    };
}
