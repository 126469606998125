import React, { useState, useEffect, useContext } from "react";
import { Panel, Page, MainContainer } from "@times-tooling/layout";
import ContextHeader from "@times-tooling/context-header";
import Breadcrumbs from "@times-tooling/breadcrumbs";
import Dropdown from "@times-tooling/dropdown";
import Loader from "@times-tooling/loader";
import { tools } from "./definitions/tools";
import axios from "axios";
import { User, Authorization } from "./types";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import { AuthContext } from "./AuthProvider";
import { config } from "./config";
import cloneDeep from "lodash/cloneDeep";

const Edit = ({ match: { params: { userId = null } = {} } = {} }) => {
  const [user, setUser] = useState<User>();
  const [queryState, setQueryState] = useState("loading");
  const authContext = useContext(AuthContext);

  const saveUser = async (user: User) => {
    try {
      await axios.patch(
        `${config.apiUrl}/update_user/${userId}`,
        user.app_metadata.authorization,
        {
          headers: {
            Authorization: `Bearer ${authContext.token}`
          }
        }
      );
    } catch (error) {
      new Noty({
        type: "error",
        text: `${user.name} update failed - ${error.response.data.error}`,
        timeout: 3000
      }).show();
    } finally {
      setQueryState("complete");
    }
    new Noty({
      type: "success",
      text: `${user.name} update saved`,
      timeout: 3000
    }).show();
  };

  const pickValidAuthorizations = (rawUserData: User): User => {
    const validUser = cloneDeep(rawUserData);
    const { authorization } = rawUserData.app_metadata;

    tools.forEach(tool => {
      validUser.app_metadata.authorization[tool.id] = tool.categories
        ? { roles: {} }
        : {};

      tool.categories
        ? tool.categories.forEach(
            category =>
              (validUser.app_metadata.authorization[tool.id].roles[
                category.id
              ] = getCurrentRole(authorization, tool.id, category.id))
          )
        : (validUser.app_metadata.authorization[tool.id].role =
            authorization[tool.id] && authorization[tool.id].role);
    });

    return validUser;
  };

  useEffect(() => {
    if (!authContext.token) return;
    const fetchUsers = async () => {
      let result: any;
      try {
        result = await axios.get(`${config.apiUrl}/get_user/${userId}`, {
          headers: {
            Authorization: `Bearer ${authContext.token}`
          }
        });
      } catch (error) {
        setQueryState("complete");
        new Noty({
          type: "error",
          text: `Failed to load data for ${userId} user ID - ${error.response.data.error}`,
          timeout: 3000
        }).show();
      }
      const user = pickValidAuthorizations(result.data.user);
      setUser(user);
      setQueryState("complete");
    };
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, authContext.token]);

  type Option = { label: string; value: string };

  const onChangeCategory = (
    selected: Option,
    actionMeta: {
      name: string;
      action:
        | "select-option"
        | "deselect-option"
        | "remove-value"
        | "pop-value"
        | "set-value"
        | "clear"
        | "create-option";
    }
  ) => {
    const [tool, category] = actionMeta.name.split(".");
    if (!user) return;

    const newUser = {
      ...user,
      app_metadata: {
        ...user.app_metadata,
        authorization: {
          ...user.app_metadata.authorization,
          [tool]: {
            roles: {
              ...user.app_metadata.authorization[tool].roles,
              [category]: selected.value
            }
          }
        }
      }
    };

    setUser(newUser);
    saveUser(newUser);
  };

  const onChangeRole = (
    selected: Option,
    actionMeta: {
      name: string;
      action?:
        | "select-option"
        | "deselect-option"
        | "remove-value"
        | "pop-value"
        | "set-value"
        | "clear"
        | "create-option";
    }
  ) => {
    const newUser = user && {
      ...user,
      app_metadata: {
        ...user.app_metadata,
        authorization: {
          ...user.app_metadata.authorization,
          [actionMeta.name]: {
            role: selected.value
          }
        }
      }
    };
    if (newUser) {
      setUser(newUser);
      saveUser(newUser);
    }
  };

  const getCurrentRole = (
    authorization: Authorization,
    tool: string,
    category: string
  ) =>
    authorization[tool] &&
    authorization[tool].roles &&
    authorization[tool].roles[category];

  const mapRoles = (roles: string[]):{ value: string; label: string }[] => roles.map(
    (role: any) => ({
      value: role,
      label: role
    })
  );

  const renderCategory = (category: any, tool: any, authorization: Authorization) => {
    const options = mapRoles(category.roles)
    return (
      <div key={category.id}>
        <h3>{category.title}</h3>
        <Dropdown
          name={`${tool.id}.${category.id}`}
          options={options}
          value={options.find(
            option =>
              option.value ===
              getCurrentRole(authorization, tool.id, category.id)
          )}
          onChange={onChangeCategory}
        />
      </div>
    )
  }

  const renderSingleTool = (tool: any, authorization: Authorization) => {
    const options = mapRoles(tool.roles)
    return (
      <Dropdown
        options={options}
        name={tool.id}
        value={options.find(
          option =>
            option.value ===
            (authorization[tool.id] && authorization[tool.id].role)
        )}
        onChange={onChangeRole}
      />
    )
  }

  const renderPermissions = (authorization: Authorization) =>
    tools.map(tool => {
      return (
        <Panel key={tool.id}>
          <h2>{tool.title}</h2>
          <div>
            {tool.categories ? (
              tool.categories.map(category => renderCategory(category, tool, authorization))
            ) : (
              renderSingleTool(tool, authorization)
            )}
          </div>
        </Panel>
      );
    });

  let content;
  if (queryState === "error") {
    content = "An error occurred while loading this user";
  } else if (queryState === "loading") {
    content = <Loader />;
  } else if (user) {
    content = renderPermissions(user.app_metadata.authorization);
  }

  return (
    <>
      <ContextHeader>
        <Breadcrumbs
          parts={[
            { link: "/", label: "Home" },
            { label: (user && user.name) || "..." }
          ]}
        />
      </ContextHeader>

      <Page>
        <MainContainer>{content}</MainContainer>
      </Page>
    </>
  );
};

export default Edit;
