import React, { useContext } from "react";
import { GlobalStyle } from "./globalStyle";
import GlobalHeader from "@times-tooling/global-header";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Home";
import Edit from "./Edit";
import Logout from "./Logout";
import { AuthContext } from "./AuthProvider";
// import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";

const AuthenticatedRoute = ({
  component: Component,
  ...rest
}: {
  path: string;
  component: any;
  exact?: boolean;
}) => <Route {...rest} component={true ? Component : Component} />;

// interface AppProps {
//   auth0: Auth0Client;
// }

const App: React.FunctionComponent = () => {
  const { user } = useContext(AuthContext);

  return (
    <Router>
      <>
        <GlobalStyle />
        <GlobalHeader links={[]} appName="User manager" user={user} />
        <AuthenticatedRoute path="/" exact={true} component={Home} />
        <AuthenticatedRoute path="/logout" exact={true} component={Logout} />
        <AuthenticatedRoute path="/edit-user/:userId" component={Edit} />
      </>
    </Router>
  );
};

export default App;
