import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Banner, Page, MainContainer } from "@times-tooling/layout";
import Table from "@times-tooling/table";
import Button from "@times-tooling/button";
import Loader from "@times-tooling/loader";
import axios from "axios";
import { User } from "./types";
import { AuthContext } from "./AuthProvider";
import { CenteredContainer } from "./styles";
import Noty from "noty";
import { config } from "./config";

const Home = function() {
  const authContext = useContext(AuthContext);
  const [users, setUsers] = useState<User[]>([]);
  const [listUsersQueryState, setListUsersQueryState] = useState("loading");
  const [loadMoreQueryState, setLoadMoreQueryState] = useState("complete");
  const [pageNumber, setPageNumber] = useState(0);

  const fetchUsers = async (pageNumber: number) => {
    try {
      const result = await axios.get(
        `${config.apiUrl}/get_users/per_page/100/page_number/${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${authContext.token}`
          }
        }
      );
      setUsers(users => [...result.data.users.reverse(), ...users]);
      setListUsersQueryState("complete");
      setLoadMoreQueryState("complete");
    } catch (error) {
      console.log(error.response);
      new Noty({
        type: "error",
        text: `Failed to load list of users - ${error.response.data.error}`
      }).show();
      setPageNumber(pageNumber - 1);
      setListUsersQueryState("complete");
      setLoadMoreQueryState("complete");
    }
  };

  useEffect(() => {
    fetchUsers(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    setLoadMoreQueryState("loading");
    const nextPage = pageNumber + 1;
    setPageNumber(nextPage);
    fetchUsers(nextPage);
  };

  const loadMoreButton =
    loadMoreQueryState === "loading" ? (
      <Loader />
    ) : (
      <Button className="load-more-button" onClick={loadMore}>Load more</Button>
    );

  return (
    <>
      <Banner>
        <div>
          <h1>Users</h1>
          <p>Listed by last login time</p>
        </div>
      </Banner>
      <Page>
        <MainContainer>
          <Table
            loading={listUsersQueryState === "loading"}
            data={users}
            columns={[
              {
                title: "Name",
                key: "name",
                sortable: true
              },
              {
                title: "Email",
                key: "email",
                sortable: true
              },
              {
                key: "actions",
                title: "Actions",
                render: (user: User) => (
                  <Link to={`/edit-user/${user.user_id}`}>Edit</Link>
                )
              }
            ]}
          />
          <CenteredContainer>
            {listUsersQueryState === "complete" && loadMoreButton}
          </CenteredContainer>
        </MainContainer>
      </Page>
    </>
  );
}

export default Home;
