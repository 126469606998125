import React, { useContext } from "react";
import { AuthContext, AuthContextType } from "./AuthProvider";

const Logout = () => {
  const authContext: AuthContextType = useContext(AuthContext);
  authContext.logout();

  return <div>Logging out</div>;
};

export default Logout;
